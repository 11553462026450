export interface IUpload {
  url: string;
}

export class Upload implements IUpload {
  url: string;
  constructor(data: any = {}) {
    this.url = data.url || 'http://data.edcontrols.nl/api/v1/upload';
  }
}

export interface IPrint {
  url: string;
}

export class Print implements Print {
  url: string;
  constructor(data: any = {}) {
    this.url = data.url || 'http://data.edcontrols.nl/api/v1/print';
  }
}

export interface ITiles {
  url: string;
}

export class Tiles implements ITiles {
  url: string;
  constructor(data: any = {}) {
    this.url = data.url || 'https://edcontrols.nl/api/v1/tiles';
  }
}

export interface IModule {
  audits: boolean;
  geomap: boolean;
}

export class Module implements IModule {
  audits: boolean;
  geomap: boolean;
  constructor(data: any = {}) {
    this.audits = data.audits || false;
    this.geomap = data.geomap || false;
  }
}

export interface ISettings {
  modules: IModule;
  print: IPrint;
  tiles: ITiles;
  upload: IUpload;
  tags: any;
}

export class SettingsModel implements ISettings {
  modules: IModule;
  print: IPrint;
  tiles: ITiles;
  upload: IUpload;
  tags: any;
  constructor(data: any = {}) {
    this.modules = data.modules || new Module();
    this.print = data.print || new Print();
    this.tiles = data.tiles || new Tiles();
    this.upload = data.upload || new Upload();
    this.tags = data.tags || {};
  }
}
