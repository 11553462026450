import _ from 'lodash';
import sanitizeHtml from 'sanitize-html';

import { apiUrl } from './api';
import { config } from './config';
import { getDate } from './dateHelper';
import { ProjectSet } from '../models/print';
import { getAPIQueryParam } from './urlController';
import { Operation, Platform } from '../models/operation';
import { getReqdElasticDataFields } from './couchdb-elasticHelper';
import { generateAllPersonList } from '../pages/tickets/TicketsService';
import { sendPostRequest, sendGetRequest } from '../utils/requestController';

// Assets
import edLogo from '../images/login/new_logo.svg';
import startedIcon from '../images/started-status-icon.svg';
import createdIcon from '../images/created-status-icon.svg';
import createdTicketIcon from '../images/tm-created.png';
import startedTicketIcon from '../images/tm-Started.png';
import completedIcon from '../images/completed-status-icon.svg';
import completedTicketIcon from '../images/tm-completed.png';
import createdArchiveIcon from '../images/created-archived-icon.svg';
import createdTicketArchIcon from '../images/tm-created-Arch.png';
import startedArchiveIcon from '../images/started-archived-icon.svg';
import startedTicketArchIcon from '../images/tm-Started-Arch.png';
import completedArchiveIcon from '../images/completed-archived-icon.svg';
import completedTicketArchIcon from '../images/tm-completed-arch.png';

/**
 * Generates an operation object based on the provided parameters.
 * @param {AppState} appState - The application state object.
 * @param {string} actionType - The type of action (created, updated, archived, deleted).
 * @param {Array<string>} changedType - The types of properties that were changed.
 * @param {Array<string>} newValue - The new values of the changed properties.
 * @param {Array<string>} oldValue - The old values of the changed properties.
 * @returns {Operation} - The generated operation object.
 * @author gaurav.rao
 */
export const generateOperations = (appState, actionType, changedType, newValue, oldValue) => {
  const data = new Operation();

  // Assign properties
  Object.assign(data, {
    changedProperties: changedType,
    oldValues: oldValue,
    newValues: newValue,
    author: appState.get('id', 'user'),
    actionType: ['created', 'updated', 'archived', 'deleted'].includes(actionType) ? actionType : undefined,
    summary: `${appState.get('name', 'user')} ${actionType === 'created' ? 'created' : 'updated'} the following fields`,
  });

  // Assign interface version
  data.platform.interfaceVersion = appState.get('version', 'product') || process.env.REACT_APP_VERSION;

  return data;
};

/**
 * Generates content data including author and last modifier information.
 * @param {object} data - The content data to be generated or updated.
 * @param {AppState} appState - The application state object.
 * @returns {object} - The content data with author and last modifier information.
 * @author gaurav.rao
 */
export const generateContent = (data, appState) => {
  // If author information is missing, assign it from the app state
  if (!data.author) {
    data.author = appState.get('id', 'user');
  }
  // Assign last modifier information from the app state
  data.lastModifier = appState.get('id', 'user');
  return data;
};

export const generateDate = (data, _appState) => {
  // If Date for creation
  if (!data.creationDate) {
    data.creationDate = getDate('now');
  }
  data.lastModifiedDate = getDate('now');
  return data;
};

export const generatePlatformData = appState => {
  const data = new Platform();
  data.interfaceVersion = appState.get('version', 'product') || process.env.REACT_APP_VERSION;
  return data;
};

/**
 * Sorts an array of objects based on the 'name' property in a case-insensitive manner.
 * @param {Array<object>} data - The array of objects to be sorted.
 * @returns {Array<object>} - The sorted array of objects.
 */
export const sortData = data => {
  return data.sort((a, b) => {
    // Convert names to lowercase for case-insensitive comparison
    const nameA = a.name?.toLowerCase();
    const nameB = b.name?.toLowerCase();
    // Compare the names and return the sorting order
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};

export const sortDataWithKey = (data, key) => {
  return data.sort((a, b) => (a[key]?.toLowerCase() < b[key]?.toLowerCase() ? -1 : a[key]?.toLowerCase() > b[key]?.toLowerCase() ? 1 : 0));
};

export const sortObjectData = data => {
  return data && Object.keys(data).length > 0 ? Object.fromEntries(Object.entries(data).sort()) : {};
};

export const sortObjectDataWithKey = (data, key = 'projectName') => {
  return data && Object.keys(data).length > 0
    ? Object.fromEntries(
        Object.entries(data).sort((a: any, b: any) => (a[1][key].toLowerCase() < b[1][key].toLowerCase() ? -1 : a[1][key].toLowerCase() > b[1][key].toLowerCase() ? 1 : 0)),
      )
    : {};
};

export const sortObjectDataWithoutKey = data => {
  return Object.keys(data).length > 0 ? Object.fromEntries(Object.entries(data).sort((a: any, b: any) => a[0].toLowerCase().localeCompare(b[0].toLowerCase()))) : {};
};

export const sortTagsDataWithoutKey = data => {
  if (data.length > 0) {
    const array = data.map(el => el.trim());
    return array.sort((a, b) => a?.toLowerCase().localeCompare(b?.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' }));
  } else {
    return [];
  }
};

export const generateFullName = (email: string, appState, lang?, personList?) => {
  const userList = personList || appState.get('usersFullName', 'project');
  if (userList && userList[email] && userList[email].firstName) {
    const name = `${userList[email].firstName} ${userList[email].lastName}`;
    const domain = email.substring(email.lastIndexOf('@') + 1);
    return `${name} (${domain})`;
  } else {
    if (email) {
      const name = email?.substring(0, email.lastIndexOf('@'));
      const domain = email?.substring(email.lastIndexOf('@') + 1);
      if (email === 'noresponsible@edcontrols.com') {
        return lang.m_lbl_no_resp;
      } else if (email === config.recurrentEmail || email === config.recurrentTicketEmail) {
        return email;
      }
      return `${name} (${domain})`;
    }
    return '';
  }
};

export const getContractName = appState => {
  const activeContractId = appState.get('active.contractId', 'contracts');
  const allContr = appState.get('allContr', 'contracts');
  return allContr[activeContractId].name;
};

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validatePhone = (phone: string) => {
  var re = /^([+[0-9]{1,5})?([0-9][0-9]+)$/;
  return re.test(phone);
};

export const isNumberKey = (evt, isPhone) => {
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (isPhone && charCode === 43) return true;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
  return true;
};

export const isAlphanumeric = evt => {
  var charCode = evt.which ? evt.which : evt.keyCode;
  if ((charCode > 47 && charCode < 58) || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) return true;
  return false;
};

export const getProjectId = async databseId => {
  if (!databseId) return {};
  const url = apiUrl.v2api + 'projects/' + databseId + '/getProjectByDB';
  return new Promise(resolve => {
    sendGetRequest(url).then(data => {
      resolve(data);
    });
  });
};
export const getFirstandLastName = (email: string, appState, personList) => {
  const userList = personList && Object.keys(personList).length > 0 ? personList : appState.get('usersFullName', 'project');
  if (userList && userList[email] && userList[email].firstName) {
    return `${userList[email].firstName} ${userList[email].lastName}`;
  } else return email?.substring(0, email.lastIndexOf('@'));
};

export const generateUsersFullName = (userObj, appState) => {
  let project_users = {};
  if (Array.isArray(userObj) && userObj.length > 0) {
    userObj.forEach(user => {
      if (user.firstName) {
        project_users[user.email] = {
          firstName: user.firstName,
          lastName: user.lastName,
        };
      } else {
        project_users[user.email] = {
          firstName: '',
          lastName: '',
        };
      }
    });
  } else {
    Object.keys(userObj).forEach(key => {
      if (userObj.hasOwnProperty(key)) {
        if (userObj[key].userInfo) {
          project_users[key] = userObj[key].userInfo.name;
        } else {
          project_users[key] = {};
          project_users[key].firstName = key.split('@')[0];
          project_users[key].lastName = '';
        }
      }
    });
  }
  appState.set('usersFullName', project_users, 'project');
  return project_users;
};

export const getAvailableTags = (databaseIds, appState, ids = [], module, isPredefined = false) => {
  const url = `${apiUrl.v2api}project/getTags`;

  // Determine modules based on logic
  const resolvedModules = !module || module[0] === 'all' ? null : module[0] === 'library' ? ['map', 'file'] : module;

  // Build request payload
  const payload = {
    projects: databaseIds,
    ...(ids.length && { documents: ids }),
    ...(resolvedModules && { modules: resolvedModules }),
  };

  // Execute request and process response
  return sendPostRequest(url, payload).then(response => {
    appState.set('availableTags', response?.userTags || [], 'project');

    const tags = isPredefined && ids.length <= 0 ? response?.preDefinedTags : response?.userTags;
    return tags?.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())) || [];
  });
};

export const getAvailableEmails = (databaseIds: string[], ids: string[], role: string, module: string = 'ticket') => {
  const url = apiUrl.v2api + `users/getUsersInfoSecure`;
  const data = {
    projects: databaseIds,
    documents: ids,
    role,
    type: module,
  };
  return new Promise(resolve => {
    sendPostRequest(url, data).then(response => {
      const { author = [], accountable = [], consulted = [], informed = [], reporter = [], support = [], responsible = [] } = response;
      const userList = _.union(author, accountable, consulted, informed, reporter, support, responsible);
      return resolve(Object.keys(Object.assign({}, ...userList)));
    });
  });
};

export const generateMapThumbnailUrl = (databaseId, appState, id) => {
  return `/api/v1/securedata/${databaseId}/${id}/tiles_0_0x0.png?access_token=${appState.get('accessToken', 'user')}`;
};

/**
 * @deprecated
 * @param databaseIds
 * @param type
 * @returns
 */
export const getAllEmailsPerRole = (databaseIds, type) => {
  if (Array.isArray(databaseIds)) {
    const data = {
      projects: databaseIds,
      type,
    };
    const url = `${apiUrl.v2api}users/uniqueRoleUserPerProject`;
    return new Promise(resolve => {
      sendPostRequest(url, data).then(data => {
        return resolve(data);
      });
    });
  } else {
    const url = `${apiUrl.v2api}users/uniqueRoleUserPerProject?projectID=${databaseIds}&type=${type}`;
    return new Promise(resolve => {
      sendGetRequest(url).then(data => {
        return resolve(data);
      });
    });
  }
};

export const constructPostBody = (model, queryString, type) => {
  let params = getAPIQueryParam(queryString, type);
  let postObj = {};
  Object.keys(model).forEach(key => {
    if (Array.isArray(model[key])) {
      const queryData = params.get(key);
      if (queryData) {
        if (key !== 'projects') {
          postObj[key] = queryData;
        } else {
          postObj[key] = queryData.split(',');
        }
      }
    } else {
      if (params.get(key)) {
        if (typeof model[key] === 'boolean') {
          postObj[key] = params.get(key) === 'true';
        } else {
          // TimeZone issue, so moving the time to min behind.
          if (key === 'fromDate' && params.get('fromDate')) {
            const selectedDate = params.get('fromDate') || 0;
            let date = new Date(selectedDate);
            date.setDate(date.getDate() - 1);
            date.setHours(23, 59, 59, 999);
            const data = new Date(date).toISOString() || '';
            postObj[key] = data;
          } else {
            const data = params.get(key) || '';
            postObj[key] = data;
          }
        }
      }
    }
  });
  switch (type) {
    case 'aggregate-ticket':
      postObj['includeFields'] = getReqdElasticDataFields('aggregate');
      postObj['aggregate'] = 'map';
      break;
    case 'aggregate-ticket-audit':
      postObj['includeFields'] = getReqdElasticDataFields('aggregate');
      postObj['aggregate'] = 'audits';
      break;
    case 'aggregate-auditTemplate':
      postObj['includeFields'] = getReqdElasticDataFields('auditTemplate');
      postObj['aggregate'] = 'groupId';
      break;
    case 'aggregate-audit':
      postObj['includeFields'] = getReqdElasticDataFields('aggregate');
      postObj['aggregate'] = 'template';
      break;
    case 'aggregate-reporter':
      postObj['includeFields'] = getReqdElasticDataFields('aggregate');
      postObj['aggregate'] = 'participants.reporter.email';
      break;
    case 'aggregate-library':
      postObj['includeFields'] = getReqdElasticDataFields('aggregate');
      postObj['aggregate'] = 'fileGroupId';
      break;
    case 'library-default':
      postObj['includeFields'] = getReqdElasticDataFields('library');
      postObj['sortby'] = 'lastModifiedDate';
      postObj['sortOrder'] = 'DESC';
      break;
    case 'library-selectAll-default':
      postObj['includeFields'] = getReqdElasticDataFields('library-selectAll');
      postObj['sortby'] = 'lastModifiedDate';
      postObj['sortOrder'] = 'DESC';
      break;
    case 'selectAll-default':
      postObj['includeFields'] = getReqdElasticDataFields('selectAll');
      postObj['sortby'] = 'lastModifiedDate';
      postObj['sortOrder'] = 'DESC';
      break;
    case 'ticket-map':
      postObj['includeFields'] = getReqdElasticDataFields('ticket-map');
      postObj['sortby'] = 'lastModifiedDate';
      postObj['sortOrder'] = 'DESC';
      break;
    default:
      postObj['includeFields'] = getReqdElasticDataFields(type);
      break;
  }
  return postObj;
};

export const sortBasedonStarred = data => {
  return Object.entries(data)
    .sort((a: any, b: any) => (a[1].isStarred === b[1].isStarred ? 0 : a[1].isStarred ? -1 : 1))
    .reduce((accum, [k, v]) => {
      accum[k] = v;
      return accum;
    }, {});
};

export const mergePersonRoleMails = async (personList, personFilter, favorite) => {
  let data;
  const len = Object.keys(personList).length;
  if (len > 0 && personFilter.length === 0) {
    data = Array.from(new Set([...personList.author, ...personList.responsible, ...personList.consulted, ...personList.informed])).sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase()),
    );
  } else if (len > 0 && personFilter.length > 0) {
    const newSet = personFilter.reduce((acc: any, value) => {
      if (value === 'LASTMODIFIEDBY') value = 'lastModifier';
      if (value === 'CREATEDBY') value = 'author';
      return new Set([...acc, ...personList[value]]);
    }, []);
    data = Array.from(newSet).sort((a: any, b: any) => {
      const keyA = Object.keys(a)[0] ? Object.keys(a)[0].toLowerCase() : '';
      const keyB = Object.keys(b)[0] ? Object.keys(b)[0].toLowerCase() : '';
      return keyA.localeCompare(keyB);
    });
  }
  const filteredPersonList: any = await generateAllPersonList(data, favorite?.person);
  return filteredPersonList;
};

export const trimLongText = (text: string, MAX_LENGTH: number) => {
  if (text.length > MAX_LENGTH) {
    return `${text.substring(0, MAX_LENGTH)}...`;
  } else {
    return text;
  }
};

export const getTicketId = ticketId => {
  return ticketId.split('').reverse().join('').toUpperCase().substr(0, 6);
};

export const getStatusIcon = (status, archived = false) => {
  const iconMap = {
    created: archived ? createdArchiveIcon : createdIcon,
    'In Progress': archived ? createdArchiveIcon : createdIcon,
    started: archived ? startedArchiveIcon : startedIcon,
  };

  return iconMap[status] || (archived ? completedArchiveIcon : completedIcon);
};

export const deleteCookie = key => {
  let expiryDate: any = new Date('1 Jan 1920');
  expiryDate = expiryDate.toUTCString();
  if (key === 'auth') {
    document.cookie = `access_token=;expires=${expiryDate};`;
    document.cookie = `refresh_token=;expires=${expiryDate};`;
    document.cookie = `user_scope=;expires=${expiryDate};`;
    document.cookie = `token_type=;expires=${expiryDate};`;
    document.cookie = `user_email=;expires=${expiryDate};`;
  }
  return true;
};

export const appendToken = (link, accessToken) => {
  link += '?access_token=' + accessToken;
  return link;
};

export const getTicketMarkerIcon = (status, isArchived) => {
  if (status.toLowerCase() === 'created') {
    return isArchived ? createdTicketArchIcon : createdTicketIcon;
  } else if (status.toLowerCase() === 'started') {
    return isArchived ? startedTicketArchIcon : startedTicketIcon;
  } else {
    return isArchived ? completedTicketArchIcon : completedTicketIcon;
  }
};

export const checkIfDataChanged = (oldObj, newObj) => {
  if (JSON.stringify(newObj) !== JSON.stringify(oldObj)) {
    return true;
  } else {
    return false;
  }
};

export const generateUUID = (shouldStore = false, appState?): string => {
  let d = new Date().getTime(),
    uuid;
  uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
  });
  if (shouldStore) {
    appState.set('deviceId', uuid, 'product');
  }
  return uuid;
};

/**
 * Generates a unique channel ID, stores it in the application state, and emits it via socket.
 * @param {AppState} appState - The application state object.
 * @param {Socket} socket - The socket object.
 * @returns {string} - The generated unique channel ID.
 * @author gaurav.rao
 */
export const generateChannelId = (appState, socket) => {
  // Generate a unique ID using generateUUID()
  const uniqueId = generateUUID();

  // Retrieve existing channel IDs from the application state, defaulting to an empty array if not present
  let storedChannelIds = appState.get('channelIds', 'projects') || [];

  // Remove any null values from the stored channel IDs
  storedChannelIds = storedChannelIds.filter(id => id !== null);

  // Add the generated unique ID to the stored channel IDs
  storedChannelIds.push(uniqueId);

  // Update the channel IDs in the application state
  appState.set('channelIds', storedChannelIds, 'projects');

  // Emit the new channel ID via socket
  socket.emit('new', { channelID: uniqueId });

  // Return the generated unique channel ID
  return uniqueId;
};

export const constructTicketThumbNailImage = (database: string, imageName: string, id: string, accessToken, isThumbnailImage: boolean = true) => {
  // Get thumbnail name
  if (isThumbnailImage) {
    imageName = imageName?.split('.').join('.256x192.');
  }
  return appendToken(apiUrl.secureData + database + '/' + id + '/' + imageName, accessToken);
};

export const getDatabaseIdFromProject = (projectId: string, appState) => {
  const allProjects = appState.get('allDb', 'projects') || {};
  const result = Object.keys(allProjects).filter(key => allProjects[key].couchDbId === projectId);
  if (result.length > 0) {
    return result[0];
  } else {
    return '';
  }
};

export const sortDataBasedOnProject = (dataSet, type = 'ticket') => {
  const projectSet = {};
  if (dataSet.length > 0) {
    dataSet.forEach(data => {
      if (!projectSet[data.database]) {
        projectSet[data.database] = new ProjectSet();
        if (type === 'audit') {
          projectSet[data.database].audit_ids.push(data.couchDbId);
          delete projectSet[data.database].ticket_ids;
        } else {
          projectSet[data.database].ticket_ids.push(data.couchDbId);
          delete projectSet[data.database].audit_ids;
        }
        projectSet[data.database].database = data.database;
      } else {
        if (type === 'audit') {
          projectSet[data.database].audit_ids.push(data.couchDbId);
        } else {
          projectSet[data.database].ticket_ids.push(data.couchDbId);
        }
      }
    });
    return Object.keys(projectSet).map(key => projectSet[key]);
  } else {
    return {};
  }
};

export const addDefaultSrc = ev => {
  ev.target.src = edLogo;
};

export const storeMailstoAppstate = (roles: any, appState) => {
  const users = Object.create(null);
  const higherRoleEmails = [];
  Object.keys(roles).forEach(role => {
    roles[role].forEach(mail => {
      const userEmail = Object.keys(mail)[0],
        user = mail[userEmail];
      if (role === 'accountable' || role === 'support') {
        higherRoleEmails.push(userEmail);
      }
      if (user) {
        if (user.hasOwnProperty('userInfo')) {
          users[userEmail] = user.userInfo.name;
        } else {
          users[userEmail] = {
            firstName: user?.name?.split('@')[0],
            lastName: '',
          };
        }
      }
    });
  });

  if (appState) appState.set('usersFullName', users, 'project');
  return { users, higherRoleEmails };
};

export const setStatusObj = (notification, appState, type?) => {
  let notificationObj = appState.get('notify', 'notification') || {};
  if (type === 'user_management') {
    if (notificationObj.hasOwnProperty(notification.channelID)) {
      notificationObj[notification.channelID] = notification;
    } else {
      notificationObj = { ...notificationObj, [notification.channelID]: notification };
    }
    appState.set('notify', notificationObj, 'notification');
  } else {
    appState.set('notify', { ...notificationObj, notification }, 'notification');
  }
};

// Remove Channel Id once we get success/error socket status to
// avoid unwanted socket connection
export const removeChannelId = (channelId, appState) => {
  var getChannelIDs = appState.get('channelIds', 'projects') || [];
  getChannelIDs = getChannelIDs.filter(function (id) {
    return id !== channelId;
  });
  appState.set('channelIds', getChannelIDs, 'projects');
};

export const allowedTags = [
  'a',
  'b',
  'br',
  'del',
  'em',
  'i',
  'li',
  'ol',
  'p',
  's',
  'small',
  'span',
  'strike',
  'strong',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
  'u',
  'ul',
];
const selfClosing = ['br'];
const allowedSchemes = ['http', 'https'];
const allowedAttributes = {
  span: ['style'],
  strong: ['style'],
  s: ['style'],
  em: ['style'],
  u: ['style'],
  li: ['style'],
  p: ['style'],
  table: ['style', 'class'],
  td: ['style', 'class', 'rowspan', 'colspan'],
  th: ['style', 'rowspan', 'colspan'],
  tr: ['style', 'rowspan', 'colspan'],
  tbody: ['style'],
  thead: ['style'],
  a: ['href', 'name', 'target'],
};

export const EMPTY_RTE_CONTENT = '<p><br></p>';
const exclusiveFilter = function (frame) {
  return frame.tag === 'table';
};

export function isEmptyParagraph(str) {
  const regex = /^<p>\s*(<br>)?\s*<\/p>$/;

  // Return true if the entire string matches the regex
  return regex.test(str);
}

const transformTags = {
  table: function () {
    return {
      tagName: 'table',
      text: '[table]',
      attribs: {
        class: 'hide-table-content',
      },
    };
  },
  td: function () {
    return {
      tagName: 'td',
      attribs: {
        class: 'hide-table-content',
      },
      text: '',
    };
  },
};
/**
 * Cleanup of restricted tags from HTML for Rich Text Editor and display
 * @param dirty The possibly dirty HTML string
 * @param display If the HTML is converted to be displayed on the DOM, defaults to false
 * @returns Sanitized string of markup
 */
export const sanitizeMarkup = (dirty: string, display: boolean = false, allowTable: boolean = true, tableHasText: boolean = false) => {
  const __html = sanitizeHtml(dirty, {
    allowedTags,
    selfClosing,
    allowedAttributes,
    allowedSchemes,
    disallowedTagsMode: 'discard',
    parseStyleAttributes: false,
    transformTags: tableHasText ? transformTags : {},
    exclusiveFilter: allowTable ? () => {} : exclusiveFilter,
  });

  return display ? { __html } : __html;
};

/**
 * Replaces "__COUNT__" placeholder in the message with the length of the provided array of IDs.
 * @param {string} message - The message containing "__COUNT__" placeholder.
 * @param {Array} ids - The array of IDs.
 * @returns {string} - The message with "__COUNT__" placeholder replaced by the length of the array of IDs.
 * @author gaurav.rao
 */
export const replaceCountInString = (message, ids) => {
  if (!message) {
    return ''; // Return empty string if message is falsy
  } else {
    // Replace "__COUNT__" with the length of the array of IDs and return the updated message
    return message.replace('__COUNT__', ids.length);
  }
};

/**
 * Creates a user object with the specified email.
 * @param {string} email - The email of the user.
 * @returns {object} - The created user object.
 * @author gaurav.rao
 */
export const createUserObject = email => {
  return {
    email,
    type: 'IB.EdBundle.Document.Person',
  };
};

export const checkWebpMap = map => {
  if (map && map.attachments && map.attachments.length > 1) {
    const firstTile = map.attachments[0];
    return firstTile.content_type === 'image/webp';
  }
};

export const handleEmailChipClick = (e, userList, snackbar, lang) => {
  e.stopPropagation();
  let email;

  if (userList && userList.length > 0) {
    const x = userList[0];
    if (typeof x === 'object' && !Array.isArray(x) && x !== null) {
      email = userList.map(users => users.email);
    } else {
      email = userList;
    }
  }
  navigator.clipboard.writeText(email).then(() => {
    snackbar({ title: lang.m_lbl_copied_to_board, hideCancel: true, open: true, autoHideDuration: 2000 });
  });
};

export const getAllAppStateProject = appState => {
  let db;
  const archivedProj = [],
    unArchivedProj = [],
    projects = {};
  const contList = appState.get('allContr', 'contracts') || {};
  const dbList = appState.get('allDb', 'projects') || {};

  const sortByProjName = function (projA, projB) {
    if (projA.projectName.toUpperCase() < projB.projectName.toUpperCase()) {
      return -1;
    }
    if (projA.projectName.toUpperCase() > projB.projectName.toUpperCase()) {
      return 1;
    }
    return 0;
  };
  return new Promise<any>(resolve => {
    for (db in dbList) {
      if (dbList.hasOwnProperty(db)) {
        if (dbList[db].roles.accountable || dbList[db].roles.support || contList[dbList[db].contract].admin) {
          const accessToken = appState.get('accessToken', 'user');
          if (!dbList[db]?.isOnPremise) {
            const parsedUrl = new URL(dbList[db].thumbImage);
            const pathname = parsedUrl.pathname;
            dbList[db].thumbImage = appendToken(pathname, accessToken);
          } else {
            dbList[db].thumbImage = appendToken(dbList[db].thumbImage, accessToken);
          }
          if (dbList[db].archived) {
            dbList[db].dbName = db;
            archivedProj.push(dbList[db]);
          } else {
            dbList[db].dbName = db;
            unArchivedProj.push(dbList[db]);
          }
        }
      }
    }
    unArchivedProj.sort(sortByProjName);
    archivedProj.sort(sortByProjName);
    for (db in unArchivedProj) {
      if (unArchivedProj.hasOwnProperty(db)) {
        projects[unArchivedProj[db].dbName] = unArchivedProj[db];
      }
    }
    //Now Archived projects is appended at last
    for (db in archivedProj) {
      if (archivedProj.hasOwnProperty(db)) {
        projects[archivedProj[db].dbName] = archivedProj[db];
      }
    }
    return resolve(projects);
  });
};

export const checkIfTagUpdateAllowed = (appState, project) => {
  const activeContractId = appState.get('allDb.active.contract', 'projects');
  const allContr = appState.get('allContr', 'contracts');
  const pricePlan = allContr[activeContractId]?.pricePlan;
  if (pricePlan === 'EXPERT' && project?.settings?.tags?.isTagEnabled && project?.settings?.tags?.isTagRestricted) {
    return true;
  }
  return false;
};
