import { apiUrl } from './api';
import { storeMailstoAppstate } from './utils';
import { getRole } from './permissionController';
import { sendGetRequest, sendPostRequest } from './requestController';

/**
 * Fetches all user emails the user can see on the module
 * @param databaseIds Array of couchDb databases
 * @param type module name
 * @returns
 */
export const getAllUsersEmails = (databaseIds: string[], type?: string, appState?: any): Promise<{ users: any; userWithRoles: any }> => {
  const url = `${apiUrl.v2api}users/getUsersInfoSecure`;
  const body = {
    projects: databaseIds,
    ...(type && { type }),
  };

  return sendPostRequest(url, body).then(data => {
    const res = storeMailstoAppstate(data, appState);
    return { users: res.users, userWithRoles: data };
  });
};

const getUserDataFromCookie = (): Promise<Record<string, string>> => {
  const cookies = document.cookie.split(';');
  const requiredKeys = ['access_token', 'refresh_token', 'user_scope', 'token_type', 'user_email'];
  const userInfo: Record<string, string> = {};

  cookies.forEach(cookie => {
    const [key, value] = cookie.trim().split('=');
    if (key && value && requiredKeys.includes(key)) {
      userInfo[key] = value;
    }
  });

  return Promise.resolve(userInfo);
};

const getProjectInfo = (databaseId: string): Promise<any> => {
  const timestamp = new Date().getTime();
  const url =
    `${apiUrl.couchDBUrl}${databaseId}/_design/doctrine_repositories/_view/type_constraint` +
    `?key="IB.EdBundle.Document.Project"&include_docs=true&cache=false&dummy=${timestamp}`;

  return sendGetRequest(url).then(data => data.rows[0].doc);
};

const checkRoutePermission = (appState, location, databaseId: string, projects?) => {
  const userRoles: any = getRole('all', appState) || {};
  const urlRoute = location.pathname.split('/')[3];
  const routeKey = urlRoute === 'audit_template' ? 'audits' : urlRoute;
  let queryParam = `?view=list&aggs_type=project&aggs_value=${databaseId}`;
  if (projects && projects.length > 0) {
    const projectIds = [];
    projects.forEach(project => projectIds.push(project.projectId));
    queryParam = queryParam + `&projects=${projectIds.join(',')}`;
  }
  const constructUrl = (routeParam: string) => {
    if (routeParam !== 'maps' && routeParam !== 'reporter') {
      return `/projects/${databaseId}/${routeParam}${queryParam}`;
    } else {
      return `/projects/${databaseId}/${routeParam}`;
    }
  };

  return new Promise<string>(resolve => {
    if (!(userRoles.accountable || userRoles.support || userRoles.contractAdmin) && (userRoles.rci || userRoles.reporter || userRoles.manager)) {
      if (userRoles.reporter && !userRoles.rci) {
        // If user is only reporter
        return resolve(constructUrl('tickets'));
      } else {
        // If user is RCI/Manager
        if (routeKey === 'audits') {
          return resolve(constructUrl('audits'));
        } else {
          return resolve(constructUrl('tickets'));
        }
      }
    } else if (userRoles.support && !(userRoles.superAdmin || userRoles.contractAdmin || userRoles.accountable)) {
      // If only support role
      if (routeKey === 'reporter') {
        return resolve(constructUrl('tickets'));
      } else {
        return resolve(constructUrl(routeKey));
      }
    } else {
      return resolve(constructUrl(routeKey));
    }
  });
};

const setCookie = (key: string, req_data, loginType?) => {
  let expiry_date: string | Date = new Date(),
    no_of_day = 1;
  if (key === 'long_live_token' || key === 'auth') {
    no_of_day = 30;
  }
  if (loginType) {
    no_of_day = 15;
  }
  expiry_date.setTime(expiry_date.getTime() + no_of_day * 24 * 60 * 60 * 1000);
  expiry_date = expiry_date.toUTCString();
  const secure = window.location.protocol === 'http:' ? '' : ';secure=true;SameSite=Lax';
  if (key === 'auth') {
    document.cookie = `access_token=${req_data.access_token};expires=${expiry_date}${secure}`;
    document.cookie = `refresh_token=${req_data.refresh_token};expires=${expiry_date}${secure}`;
    document.cookie = `user_scope=${req_data.scope};expires=${expiry_date}${secure}`;
    document.cookie = `token_type=${req_data.token_type};expires=${expiry_date}${secure}`;
    document.cookie = `user_email=${req_data.user_email};expires=${expiry_date}${secure}`;
  } else if (key === 'long_live_token') {
    document.cookie = `long_live_token=${req_data};expires=${expiry_date}${secure}`;
  }
};

const getCookie = reqCookie => {
  let name = reqCookie + '=',
    cookie,
    tempCookie;
  cookie = document.cookie.split(';');
  for (let i = 0; i < cookie.length; i += 1) {
    tempCookie = cookie[i];
    if (tempCookie.charAt(0) === ' ') {
      tempCookie = tempCookie.substring(1);
    }
    if (tempCookie.indexOf(name) === 0) {
      return tempCookie.substring(name.length, tempCookie.length);
    }
  }
  return false;
};

export { getProjectInfo, checkRoutePermission, getUserDataFromCookie, setCookie, getCookie };
